<template>
  <div>
    <both-colum-page
      class="equipmentManage"
      :title="$t('equipmentManage.customTitle')"
      right="200px"
      :formData="formData"
      :config="config"
      @search="search"
    >
      <div slot="left-title">
        <span>{{ $t('equipmentManage.customGroupTitle') }}</span>
        <el-button
          v-if="$page.showBtn('CUSTOM_EQUIPMENT_GROUP_MANAGE_ADD')"
          style="position: absolute; top: 10px; right: 10px; font-size: 12px"
          icon="el-icon-plus"
          size="mini"
          @click="addFirstGroup"
        >
          {{ $t('equipmentManage.addFirstGroup') }}
        </el-button>
      </div>
      <base-tree
        slot="left"
        ref="baseTree"
        :baseUrl="$page.getBaseUrl()"
        :url="'/' + $util.getLocalStorage('apiprefix') + $api.customEquipmentGroupFind"
        v-model="treeRow"
        @nodeClick="nodeClick"
      >
        <template v-slot:default="{ data }">
          <div
            style="display: flex; justify-content: space-between; width: 100%"
          >
            <div>{{ data.name }}</div>
            <div class="groupOptions">
              <i
                class="el-icon-plus"
                v-if="
                  data.level !== 'FIVE' &&
                  $page.showBtn('CUSTOM_EQUIPMENT_GROUP_MANAGE_ADD')
                "
                @click.stop="groupMethod(data, 'add')"
              />
              <i
                v-if="$page.showBtn('CUSTOM_EQUIPMENT_GROUP_MANAGE_EDIT')"
                class="el-icon-edit"
                @click.stop="groupMethod(data, 'edit')"
              />
              <i
                v-if="$page.showBtn('CUSTOM_EQUIPMENT_GROUP_MANAGE_DELETE')"
                class="el-icon-delete"
                @click.stop="groupMethod(data, 'del')"
              />
            </div>
          </div>
        </template>
      </base-tree>
      <template v-slot:functionButton>
        <div class="functionButton">
          <el-button
            v-if="$page.showBtn('CUSTOM_EQUIPMENT_MANAGE_ADD')"
            size="mini"
            @click="equipmentMethod('add')"
          >
            <i class="el-icon-plus" />
            {{ $t('functionEnum.CUSTOM_EQUIPMENT_MANAGE_ADD') }}
          </el-button>
        </div>
      </template>
      <table-list
        ref="tableList"
        class="eqTableList"
        :baseUrl="$page.getBaseUrl()"
        :url="'/' + $util.getLocalStorage('apiprefix') + $api.customEquipmentFind"
        :columns="columns"
        :defaultParams="{
          needParentUserAccountGroup: 'YES',
        }"
        :treeProps="{ children: 'children' }"
        v-model="tableRow"
      >
        <!-- 创建时间 -->
        <template v-slot:createTime="{ row }">
          {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
        </template>
        <!-- 类型 -->
        <template v-slot:level="{ row }">
          {{ levelNames[row.level] }}
        </template>
        <!-- 所属分组 -->
        <template v-slot:equipmentGroup="{ row }">
          {{ row.equipmentGroup.name }}
        </template>
        <!-- 操作 -->
        <template v-slot:option="{ row }">
          <template v-if="row.level === 'ONE'">
            <el-button
              v-if="$page.showBtn('CUSTOM_EQUIPMENT_MANAGE_ADD')"
              type="text"
              @click="sparePartMethod('add', row)"
              style="margin-right: 26px"
            >
              <!-- lang: 新增部件 -->
              {{ $t('equipmentManage.addPort') }}
            </el-button>
            <el-button
              v-if="$page.showBtn('CUSTOM_EQUIPMENT_MANAGE_EDIT')"
              type="text"
              @click="equipmentMethod('edit', row)"
            >
              <!-- lang: 修改 -->
              {{ $t('base.edit') }}
            </el-button>
            <el-button
              v-if="$page.showBtn('CUSTOM_EQUIPMENT_MANAGE_DELETE')"
              type="text"
              @click="equipmentMethod('del', row)"
            >
              <!-- lang: 删除 -->
              {{ $t('base.del') }}
            </el-button>
            <el-button
              v-if="$page.showBtn('CUSTOM_EQUIPMENT_MANAGE_COPY')"
              type="text"
              @click="copyDataMethod(row, 'copy')"
            >
              <!-- lang: 复制到 -->
              {{ $t('functionEnum.CUSTOM_EQUIPMENT_MANAGE_COPY') }}
            </el-button>
            <el-button
              v-if="$page.showBtn('CUSTOM_EQUIPMENT_MANAGE_MOVE')"
              type="text"
              @click="copyDataMethod(row, 'move')"
            >
              <!-- lang: 移动到 -->
              {{ $t('functionEnum.CUSTOM_EQUIPMENT_MANAGE_MOVE') }}
            </el-button>
            <el-button
              v-if="$page.showBtn('CUSTOM_EQUIPMENT_MANAGE_EXPORT')"
              type="text"
              @click="equipmentMethod('export', row)"
            >
              {{ $t('functionEnum.CUSTOM_EQUIPMENT_MANAGE_EXPORT') }}
            </el-button>
          </template>
          <template v-else-if="row.level === 'TWO'">
            <el-button
              v-if="$page.showBtn('CUSTOM_EQUIPMENT_MANAGE_ADD')"
              type="text"
              @click="faultMethod('add', row)"
            >
              <!-- lang: 新增故障信息 -->
              {{ $t('equipmentManage.addIssue') }}
            </el-button>
            <el-button
              v-if="$page.showBtn('CUSTOM_EQUIPMENT_MANAGE_EDIT')"
              type="text"
              @click="sparePartMethod('edit', row)"
            >
              <!-- lang: 修改 -->
              {{ $t('base.edit') }}
            </el-button>
            <el-button
              v-if="$page.showBtn('CUSTOM_EQUIPMENT_MANAGE_DELETE')"
              type="text"
              @click="sparePartMethod('del', row)"
            >
              <!-- lang: 删除 -->
              {{ $t('base.del') }}
            </el-button>
            <el-button
              v-if="$page.showBtn('CUSTOM_EQUIPMENT_MANAGE_COPY')"
              type="text"
              @click="copyDataMethod(row, 'copy')"
            >
              <!-- lang: 复制到 -->
              {{ $t('functionEnum.CUSTOM_EQUIPMENT_MANAGE_COPY') }}
            </el-button>
            <el-button
              v-if="$page.showBtn('CUSTOM_EQUIPMENT_MANAGE_MOVE')"
              type="text"
              @click="copyDataMethod(row, 'move')"
            >
              <!-- lang: 移动到 -->
              {{ $t('functionEnum.CUSTOM_EQUIPMENT_MANAGE_MOVE') }}
            </el-button>
          </template>
          <template v-else>
            <!-- lang: 修改 -->
            <el-button
              v-if="$page.showBtn('CUSTOM_EQUIPMENT_MANAGE_EDIT')"
              type="text"
              @click="faultMethod('edit', row)"
              style="margin-left: 94px"
            >
              {{ $t('base.edit') }}
            </el-button>
            <!-- lang: 删除 -->
            <el-button
              v-if="$page.showBtn('CUSTOM_EQUIPMENT_MANAGE_DELETE')"
              type="text"
              @click="faultMethod('del', row)"
            >
              {{ $t('base.del') }}
            </el-button>
            <el-button
              v-if="$page.showBtn('CUSTOM_EQUIPMENT_MANAGE_COPY')"
              type="text"
              @click="copyDataMethod(row, 'copy')"
            >
              <!-- lang: 复制到 -->
              {{ $t('functionEnum.CUSTOM_EQUIPMENT_MANAGE_COPY') }}
            </el-button>
            <el-button
              v-if="$page.showBtn('CUSTOM_EQUIPMENT_MANAGE_MOVE')"
              type="text"
              @click="copyDataMethod(row, 'move')"
            >
              <!-- lang: 移动到 -->
              {{ $t('functionEnum.CUSTOM_EQUIPMENT_MANAGE_MOVE') }}
            </el-button>
          </template>
        </template>
      </table-list>
      <div slot="other" class="enquFormBox scollBar">
        <div class="eqDetailList" v-if="tableRow">
          <!-- 设备简介 -->
          <show-equipment-detail
            v-if="tableRow.level === 'ONE'"
            :tableRow="tableRow"
            @view="equipmentMethod"
          />
          <!-- 部件简介 -->
          <show-spare-part-detail
            v-else-if="tableRow.level === 'TWO'"
            :tableRow="tableRow"
            @view="sparePartMethod"
          />
          <!-- 故障信息简介 -->
          <show-fault-detail v-else :tableRow="tableRow" @view="faultMethod" />
        </div>
      </div>
      <!-- 设备分组 -->
      <group-detail
        v-if="showGroupDetail"
        :treeRow="treeRow"
        :isFirstGroup="isFirstGroup"
        @closePage="closeGroupPage"
      />
      <!-- 设备 -->
      <equipment-detail
        v-if="showEquipmentDetail"
        :tableRow="tableRow"
        @closePage="closeEquipmentPage"
      />
      <!-- 部件 -->
      <spare-part-detail
        v-if="showSparePartDetail"
        :tableRow="tableRow"
        @closePage="closeSparePartPage"
      />
      <!-- 故障信息 -->
      <fault-detail
        v-if="showFaultDetail"
        :tableRow="tableRow"
        @closePage="closeFaultPage"
      />
      <!-- 复制时弹出的设备分组 -->
      <dialog-tree
        ref="dialogTree"
        v-if="showDialogTree"
        :title="$t('equipmentManage.selectGroup_1')"
        :baseUrl="$page.getBaseUrl()"
        :url="'/' + $util.getLocalStorage('apiprefix') + $api.customEquipmentGroupFind"
        :defaultExpandAll="true"
        height="500px"
        v-model="dialogTreeRow"
        @cancel="showDialogTree = false"
        @confirm="dialogTreeConfirm"
      >
      </dialog-tree>
      <!-- 复制时弹出的设备、备件、故障列表 -->
      <dialog-table-list
        ref="dialogTableList"
        width="1200px"
        v-if="showDialogTableList"
        :title="dialogTitle"
        :baseUrl="$page.getBaseUrl()"
        :url="'/' + $util.getLocalStorage('apiprefix') + $api.customEquipmentFind"
        :columns="dialogColumns"
        :formData="dialogFormData"
        :config="dialogConfig"
        :treeProps="{ children: 'children' }"
        v-model="dialogTableRow"
        @search="dialogSearch"
        @cancel="showDialogTableList = false"
        @confirm="dialogTableConfirm"
      >
        <!-- 创建时间 -->
        <template v-slot:createTime="{ row }">
          {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
        </template>
        <!-- 类型 -->
        <template v-slot:level="{ row }">
          {{ levelNames[row.level] }}
        </template>
      </dialog-table-list>
    </both-colum-page>
    <!-- 导出二维码 -->
    <export-detail
      v-if="showExportDetail"
      :tableRow="tableRow"
      @closePage="closePage"
    />
  </div>
</template>

<script>
import GroupDetail from './groupDetail'
import EquipmentDetail from './equipmentDetail'
import SparePartDetail from './sparePartDetail'
import FaultDetail from './faultDetail'
import ShowEquipmentDetail from './showEquipmentDetail'
import ShowSparePartDetail from './showSparePartDetail'
import ShowFaultDetail from './showFaultDetail'
import ExportDetail from './exportDetail'

export default {
  components: {
    GroupDetail,
    EquipmentDetail,
    SparePartDetail,
    FaultDetail,
    ShowEquipmentDetail,
    ShowSparePartDetail,
    ShowFaultDetail,
    ExportDetail,
  },
  data() {
    return {
      // 设备分组
      treeRow: null,
      showGroupDetail: false,
      isFirstGroup: false,
      // 搜索
      formData: {},
      config: [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 12,
          tagProps: {
            placeholder: this.$t('equipmentManage.nameOrNotes'),
          },
        },
        // lang:所属部门
        {
          tag: 'el-cascader',
          label: this.$t('adminAccount.accountGroup'),
          prop: 'userGroupId',
          span: 12,
          tagProps: {
            options: [],
            props: {
              label: 'name',
              value: 'id',
            },
          },
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 12,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ],
      // 表格页
      tableRow: null,
      content: [],
      levelNames: {
        ONE: this.$t('equipmentManage.equipmentInfo'),
        TWO: this.$t('equipmentManage.partInfo'),
        THREE: this.$t('equipmentManage.faultInfo'),
      },
      columns: [
        // lang:类型
        {
          label: this.$t('equipmentManage.type'),
          prop: 'level',
          width: '140px',
        },
        // lang:所属分组
        {
          label: this.$t('equipmentManage.equipmentGroup'),
          prop: 'equipmentGroup',
        },
        // lang:所属部门
        {
          label: this.$t('equipmentManage.equipmentUserAccountGroup'),
          prop: 'userAccountGroup',
          callback(row) {
            return row.userAccountGroup && row.userAccountGroup.name
          },
        },
        // lang:名称
        {
          label: this.$t('equipmentManage.name'),
          prop: 'name',
        },
        // lang:编号
        {
          label: this.$t('equipmentManage.number'),
          prop: 'number',
        },
        // lang:型号
        {
          label: this.$t('equipmentManage.model'),
          prop: 'model',
        },
        // lang:规格
        {
          label: this.$t('equipmentManage.specifications'),
          prop: 'specifications',
        },
        // lang:创建时间
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
        },
        // lang:操作
        {
          label: this.$t('base.option'),
          prop: 'option',
          width: '380px',
        },
      ],
      // 设备
      showEquipmentDetail: false,
      // 部件
      showSparePartDetail: false,
      // 故障信息
      showFaultDetail: false,
      // 弹出分组树
      showDialogTree: false,
      dialogTreeRow: null,
      // 弹出表格
      dialogTitle: '',
      showDialogTableList: false,
      dialogColumns: [
        // lang:类型
        {
          label: this.$t('equipmentManage.type'),
          prop: 'level',
          width: '140px',
        },
        // lang:所属分组
        {
          label: this.$t('equipmentManage.equipmentGroup'),
          prop: 'equipmentGroup',
          callback(row) {
            return row.userAccountGroup.name
          },
        },
        // lang:所属部门
        {
          label: this.$t('equipmentManage.equipmentUserAccountGroup'),
          prop: 'userAccountGroup',
          callback(row) {
            return row.userAccountGroup.name
          },
        },
        // lang:名称
        {
          label: this.$t('equipmentManage.name'),
          prop: 'name',
        },
        // lang:编号
        {
          label: this.$t('equipmentManage.number'),
          prop: 'number',
        },
        // lang:型号
        {
          label: this.$t('equipmentManage.model'),
          prop: 'model',
        },
        // lang:规格
        {
          label: this.$t('equipmentManage.specifications'),
          prop: 'specifications',
        },
        // lang:创建时间
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
        },
      ],
      dialogTableRow: null,
      // 选择设备搜索
      dialogFormData: {},
      dialogConfig: [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 12,
          tagProps: {
            placeholder: this.$t('equipmentManage.nameOrNotes'),
          },
        },
        // lang:所属分组
        {
          tag: 'el-cascader',
          label: this.$t('equipmentManage.equipmentGroup'),
          prop: 'groupId',
          span: 12,
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
            props: {
              label: 'name',
              value: 'id',
            },
          },
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 12,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ],
      // 导出二维码
      showExportDetail: false,
    }
  },
  computed: {
    beCustomIsYes() {
      const operator = this.$util.getSessionStorage('operator')
      return operator.company.beCustom === 'YES'
    },
  },
  mounted() {
    this.userAccountGroupFindTree()
    // 查询所属分组
    this.equipmentGroupFind()
  },
  methods: {
    // 查询所属分组
    equipmentGroupFind() {
      this.$ajax({
        baseUrl: this.$page.getBaseUrl(),
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.customEquipmentGroupFind,
      }).then((data) => {
        this.dialogConfig[1].tagProps.options = data
      })
    },
    // 关闭页面
    closePage() {
      this.showExportDetail = false
    },
    // 选择复制设备确定
    dialogTableConfirm() {
      // 选择设备
      if (
        this.tableRow.level === 'TWO' &&
        this.dialogTableRow.level !== 'ONE'
      ) {
        this.$element.showMsg(
          this.$t('equipmentManage.selectEquipmentTips'),
          'warning'
        )
        return
      }
      // 选择备件
      if (
        this.tableRow.level === 'THREE' &&
        this.dialogTableRow.level !== 'TWO'
      ) {
        this.$element.showMsg(
          this.$t('equipmentManage.selectPartTips'),
          'warning'
        )
        return
      }
      this.showDialogTableList = false
      const params = {
        groupId: this.dialogTableRow.equipmentGroup.id,
        psn: this.dialogTableRow.sn,
        sn: this.tableRow.sn,
      }
      if (this.typeData === 'copy') {
        this.equipmentCopyEquipment(params)
      } else {
        this.equipmentMoveEquipment(params)
      }
    },
    // 弹出设备查询
    dialogSearch() {
      const dialogFormData = this.$util.copyData(this.dialogFormData)
      if (dialogFormData.groupId.length) {
        dialogFormData.groupId = dialogFormData.groupId.pop()
      } else {
        delete dialogFormData.groupId
      }
      this.$refs.dialogTableList.searchList(dialogFormData)
    },
    // 选择复制分组确定
    dialogTreeConfirm() {
      if (this.dialogTreeRow.children && this.dialogTreeRow.children.length) {
        this.$element.showMsg(this.$t('equipmentManage.copyEqTips'), 'warning')
        return
      }
      this.showDialogTree = false
      const params = {
        groupId: this.dialogTreeRow.id,
        sn: this.tableRow.sn,
      }
      if (this.typeData === 'copy') {
        this.equipmentCopyEquipment(params)
      } else {
        this.equipmentMoveEquipment(params)
      }
    },
    // 复制数据
    equipmentCopyEquipment(params) {
      this.$ajax({
        baseUrl: this.$page.getBaseUrl(),
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.customEquipmentCopyEquipment,
        data: params,
      }).then(() => {
        // lang:复制成功
        this.$element.showMsg(this.$t('base.copySuccess'), 'success')
        this.search(true)
      })
    },
    // 移动数据
    equipmentMoveEquipment(params) {
      this.$ajax({
        baseUrl: this.$page.getBaseUrl(),
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.customEquipmentMoveEquipment,
        data: params,
      }).then(() => {
        // lang:移动成功
        this.$element.showMsg(this.$t('base.moveSuccess'), 'success')
        this.search(true)
      })
    },
    // 打开选择复制到哪里弹框
    copyDataMethod(row, type) {
      this.typeData = type
      const { level } = row
      this.tableRow = row
      this.dialogTreeRow = null
      this.dialogTableRow = null
      // 复制设备
      if (level === 'ONE') {
        this.showDialogTree = true
        return
      }
      // 复制备件和故障
      if (level === 'TWO') {
        this.dialogTitle = this.$t('equipmentManage.selectEquipmentTips')
      } else {
        this.dialogTitle = this.$t('equipmentManage.selectPartTips')
      }
      // 清除筛选数据
      this.dialogFormData = {}
      this.showDialogTableList = true
    },
    // 查询部门
    userAccountGroupFindTree() {
      this.$ajax({
        url: this.$api.userAccountGroupFindTree,
        data: {
          companyUid: this.$store.getters.company.uid,
          scope: 'COMPANY',
        },
      }).then((data) => {
        this.config[1].tagProps.options = data
      })
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      // 点击查询时，重置设备分组选择
      if (isActiveQuery) {
        this.$refs.baseTree.reset()
        delete this.formData.groupId
      }
      const formData = this.$util.copyData(this.formData)
      if (formData.userGroupId.length) {
        formData.userGroupId = formData.userGroupId.pop()
      } else {
        delete formData.userGroupId
      }
      this.$refs.tableList.searchList(formData, isActiveQuery)
    },
    // 选择设备分组
    nodeClick() {
      this.formData.groupId = this.treeRow.id
      this.search()
    },
    // 新增一级分组
    addFirstGroup() {
      this.isFirstGroup = true
      this.$store.commit('updateCmd', 'add')
      this.showGroupDetail = true
    },
    // 分组按钮操作方法
    groupMethod(data, method) {
      if (method === 'del') {
        this.deleteMethod(this.$api.customEquipmentGroupDelete, data, () => {
          this.closeGroupPage('delete')
        })
        return
      }
      this.isFirstGroup = false
      this.treeRow = data
      this.$store.commit('updateCmd', method)
      if (method === 'add') {
        this.$ajax({
          baseUrl: this.$page.getBaseUrl(),
          url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.customEquipmentHasEquipment,
          data: {
            groupId: data.id,
          },
        }).then((isPass) => {
          if (isPass) {
            this.$element.showMsg(
              this.$t('equipmentManage.hasEquipment'),
              'warning'
            )
          } else {
            this.showGroupDetail = true
          }
        })
      } else {
        this.showGroupDetail = true
      }
    },
    // 删除方法
    deleteMethod(url, data, fn) {
      this.$element
        .showConfirm({
          content: this.$t('base.deleteConfirm'),
        })
        .then(() => {
          this.$ajax({
            baseUrl: this.$page.getBaseUrl(),
            url,
            data,
          }).then(() => {
            // lang:删除成功
            this.$element.showMsg(this.$t('base.deleteSuccess'), 'success')
            fn && fn()
          })
        })
    },
    // 关闭分组详情页回掉
    closeGroupPage(method) {
      this.showGroupDetail = false
      if (method && method !== 'cancel') {
        // 重新查询设备分组
        this.$refs.baseTree.searchList()
        // 重新查询人员
        delete this.formData.groupId
        this.search()
      }
    },
    //-----------------------------------设备---------------------------------------------
    // 关闭设备详情页回调
    closeEquipmentPage(method) {
      this.showEquipmentDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    // 设备操作
    equipmentMethod(method, row) {
      if (row) this.tableRow = row
      this.$store.commit('updateCmd', method)
      this.$store.commit(
        'updateDialogTitle',
        method === 'add'
          ? this.$t('equipmentManage.addEquipment')
          : this.$t(`base.${method}`)
      )
      switch (method) {
        case 'add':
        case 'edit':
        case 'view':
          this.showEquipmentDetail = true
          break
        case 'del':
          this.deleteMethod(
            '/' + this.$util.getLocalStorage('apiprefix') + this.$api.customEquipmentDelete,
            this.tableRow,
            () => {
              this.closeEquipmentPage('delete')
            }
          )
          break
        case 'export':
          if (!this.tableRow) {
            this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
            return
          }
          this.showExportDetail = true
          break
        default: // do something
      }
    },
    //-----------------------------------部件---------------------------------------------
    // 关闭部件详情页回调
    closeSparePartPage(method) {
      this.showSparePartDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    // 部件操作
    sparePartMethod(method, row) {
      if (row) this.tableRow = row
      this.$store.commit('updateCmd', method)
      this.$store.commit(
        'updateDialogTitle',
        method === 'add'
          ? this.$t('equipmentManage.addPort')
          : this.$t(`base.${method}`)
      )
      switch (method) {
        case 'add':
        case 'edit':
        case 'view':
          this.showSparePartDetail = true
          break
        case 'del':
          this.deleteMethod(
            '/' + this.$util.getLocalStorage('apiprefix') + this.$api.customEquipmentDelete,
            this.tableRow,
            () => {
              this.closeEquipmentPage('delete')
            }
          )
          break
        default: // do something
      }
    },
    //---------------------------------故障信息-------------------------------------------
    // 关闭故障信息页回调 closeFaultPage
    closeFaultPage(method) {
      this.showFaultDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    // 故障信息操作
    faultMethod(method, row) {
      if (row) this.tableRow = row
      this.$store.commit('updateCmd', method)
      this.$store.commit(
        'updateDialogTitle',
        method === 'add'
          ? this.$t('equipmentManage.addIssue')
          : this.$t(`base.${method}`)
      )
      switch (method) {
        case 'add':
        case 'edit':
        case 'view':
          this.showFaultDetail = true
          break
        case 'del':
          this.deleteMethod(
            '/' + this.$util.getLocalStorage('apiprefix') + this.$api.customEquipmentDelete,
            this.tableRow,
            () => {
              this.closeEquipmentPage('delete')
            }
          )
          break
        default: // do something
      }
    },
  },
}
</script>

<style lang="scss">
@import './_index.scss';
</style>
